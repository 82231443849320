<template>
    <main class="container w-full mx-auto py-10 px-4 md:px-0 xl:w-6/12">
        <h1 class="font-bold uppercase text-2xl tracking-tight text-webmoney mb-4">
            {{ $t("search.searchTitle") }}
        </h1>
        <p class="text-xl text-gray-500 mb-5">
            {{ $t("search.searchSubtitle") }}
        </p>
        <Search />
        <Results />
        <div
            class="fixed inset-0 backdrop-filter backdrop-blur-md"
            @click="hideList"
            v-if="showList && sitesList && sitesList.length > 0"
        ></div>
        <button
            class="
                bg-webmoney
                hover:bg-blue-400
                text-white
                px-3
                py-2
                text-center
                shadow
                rounded
                block
                ml-auto
            "
            v-if="showAddPageButton"
            @click="addPage"
        >
            {{ $t("search.addPageButton") }}
        </button>
    </main>
</template>

<script>
import { mapState } from "vuex";
import Results from "@/components/search/Results.vue";
import Search from "@/components/search/Search.vue";

export default {
    components: {
        Results,
        Search,
    },
    computed: mapState({
        sitesList: (state) => state.search.sitesList,
        showList: (state) => state.search.showList,
        showAddPageButton: (state) => state.search.showAddPageButton,
    }),
    methods: {
        hideList() {
            this.$store.dispatch("search/hideSitesList");
        },
        addPage() {
            this.$store.dispatch("search/addPage");
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
