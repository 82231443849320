<template>
    <div>
        <SiteItems :sites="sitesResults" v-if="query" />
    </div>
</template>

<script>
import { mapState } from "vuex";
import SiteItems from "@/components/SiteItems.vue";
export default {
    components: {
        SiteItems,
    },
    computed: mapState({
        sitesResults: (state) => state.search.sitesResults,
    }),
    mounted() {
        this.query = this.$route.query.q;
    },
    data() {
        return {
            query: null,
        };
    },
    watch: {
        $route() {
            this.query = this.$route.query.q;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>