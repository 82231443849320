<template>
  <div class="flex relative z-10 mb-4">
    <form class="flex w-full" @submit.prevent="formSubmit">
      <input
        type="text"
        placeholder="Введите домен сайта"
        class="
          p-2
          border
          rounded
          w-full
          -mr-1
          border-gray-300
          dark:border-gray-700
          focus:outline-none focus:ring-1 focus:border-webmoney
          dark:bg-gray-800
        "
        v-model="domain"
        autocomplete="off"
        @keydown.down.prevent="keyDown"
        @keydown.up.prevent="keyUp"
      />
      <button
        type="submit"
        class="
          py-1
          px-3
          bg-webmoney
          hover:bg-blue-400
          text-white
          rounded
          flex
          items-center
        "
      >
        <SearchIcon class="w-4 h-4 mr-2" />
        <span class="text-sm"> {{ $t("search.searchButton") }} </span>
      </button>
    </form>
    <button
      type="button"
      class="absolute top-1 right-24 p-2 bg-white dark:bg-gray-800"
      @click="clearDomain"
      v-if="showDomainClearButton"
    >
      <XIcon class="w-5 h-5 hover:text-gray-400 cursor-pointer" />
    </button>
    <div
      class="
        absolute
        bg-white
        dark:bg-gray-700
        left-0
        w-full
        top-12
        border
        dark:border-gray-700
        rounded-b-lg
      "
      v-if="showList && sitesList && sitesList.length > 0"
    >
      <ul class="flex flex-col">
        <li v-for="(item, index) in sitesList" :key="index">
          <button
            @click="setCurrentIndex(index)"
            type="button"
            class="
              flex
              w-full
              py-2
              px-4
              overflow-hidden
              hover:bg-gray-100
              dark:hover:bg-gray-800
            "
            :class="{
              'bg-gray-200': currentSiteIndex == index,
            }"
          >
            <img :src="root + item.icon" alt="icon" class="w-6 h-6 mr-4" />
            <span>
              {{ item.domain }}
            </span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { XIcon, SearchIcon } from "vue-feather-icons";
import { mapState } from "vuex";

export default {
  components: {
    XIcon,
    SearchIcon,
  },
  computed: {
    domain: {
      get() {
        return this.$store.state.search.domain;
      },
      set(val) {
        this.$store.dispatch("search/setDomain", { query: val });
      },
    },
    ...mapState({
      sitesList: (state) => state.search.sitesList,
      showList: (state) => state.search.showList,
      currentSiteIndex: (state) => state.search.currentSiteIndex,
      showDomainClearButton: (state) => state.search.showDomainClearButton,
    }),
  },
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
    };
  },
  mounted() {
    this.setSitesResults();
  },
  methods: {
    clearDomain() {
      this.domain = null;
      this.$store.dispatch("search/clearSitesResults");
      this.$store.dispatch("search/hideAddPageButton");
      this.$store.dispatch("search/formSubmited");
    },
    keyDown() {
      this.$store.dispatch("search/incrCurrentSiteIndex");
    },
    keyUp() {
      this.$store.dispatch("search/decrCurrentSiteIndex");
    },
    formSubmit() {
      this.$store.dispatch("search/formSubmited");
    },
    setCurrentIndex(index) {
      this.$store.dispatch("search/setCurrentSiteIndex", index);
      this.$store.dispatch("search/formSubmited");
    },
    setSitesResults() {
      let query = this.$route.query.q;
      this.$store.dispatch("search/setDomain", { query, hideList: true });
      this.$store.dispatch("search/setSitesResults");
    },
  },
  watch: {
    $route() {
      this.setSitesResults();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>